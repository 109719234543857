import React, { memo, useCallback, useState } from 'react';
import clsx from 'clsx';
import $ from 'jquery';

const SEARCH_ADDRESS_URL = 'https://api.addressian.co.uk/v2/autocomplete/';

const AddressAutocomplete = memo(({ value: initialValue, placeholder, onChange, onBlur = () => { }, sortBy = 'postcode', isValid = true }) => {
    const [addresses, setAddresses] = useState([]);
    const [value, onChangeInput] = useState(initialValue || '');

    const _onChange = ({ target: { value: _value } }) => {
        onChangeInput(_value);
        if (!_value) return setAddresses([]);

        $.ajax({
            url: `${SEARCH_ADDRESS_URL}${_value}`,
            type: 'Get',
            headers: {
                'x-api-key': 'GWzuIJLaph90wGOzcqzn07Hd681gTVfo4c5lfpUM',
            },
            success: (d) => {
                setAddresses(d.sort((a, b) => (a[sortBy] > b[sortBy] ? 1 : -1)));
            },
            error: (e) => {
                console.error(e);
            },
        });
    };

    const _onSelect = (_value, inputValue) => () => {
        onChange(_value);
        onChangeInput(inputValue);
        setAddresses([]);
    };

    return (
        <div className="signup-form__autocomplete">
            <input
                onBlur={onBlur()}
                type="text"
                value={value}
                placeholder={placeholder}
                className={clsx('signup-form__row', 'signup-form__input', {
                    ['signup-form__input-error']: !isValid,
                })}
                onChange={_onChange}
                autoComplete='new-password'
            />
            {!!addresses.length && (
                <div className="signup-form__autocomplete-list">
                    {addresses.map(({ county, city, postcode, address = [] }, idx) => {
                        const _value = `${address.join(', ')}, ${city}, ${
                            county + ',' || ''
                        }${postcode}`;
                        const selectedAddress = { address, city, county, postcode };
                        return (
                            <div
                                key={`${_value}_${idx}`}
                                onClick={_onSelect(selectedAddress, _value)}
                                className="signup-form__autocomplete-list-item"
                            >
                                {_value}
                            </div>
                        );
                    })}
                </div>
            )}
            {!isValid && <span className="signup-form__error-message">Required field</span>}
        </div>
    );
});

export default AddressAutocomplete;
