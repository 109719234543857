import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import SignupGeneric from "./SignupGeneric/SignupGeneric";
import "bootstrap/dist/css/bootstrap.min.css";
import "./signup.scss";
import TagManager from "react-gtm-module";

const Dynamic = () => {
    const { formName } = useParams();
    const [signUpFormVals, setSignUpFormVals] = useState({});
    const [ gTagManagerId, setGTagManagerId]=useState(null);

    const loadData = () => {
        fetch(`/api/v1/signup/${formName}`)
            .then((response) => response.json())
            .then((data) => {
                setSignUpFormVals(data)
                setGTagManagerId(data.gTagManagerId);
            });
    }

    useEffect(() => {
        loadData();
    }, []);
    
    useEffect(()=>{
        if (gTagManagerId!=null) {
            const tagManagerArgs = {
                gtmId: gTagManagerId
            }

            TagManager.initialize(tagManagerArgs);
        }
    }, [gTagManagerId]);

    //useEffect(() => {
    //    console.log(signUpFormVals);
    //}, [signUpFormVals]);

    return (
        <SignupGeneric signUpFormVals={signUpFormVals} />
    );
}

export default Dynamic;