import React, { PureComponent } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import toastr from 'toastr';
import AddressAutocomplete from './AddressAutocomplete';
import PasswordTextField from './PasswordTextField';
import MinTextField from './MinTextField';
import ModalWindow from './ModalFetch';
import $ from 'jquery';
import 'jquery-modal';

class SignUpPageGeneric extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            step: 1,
            campaignName: '',
            email: '',
            emailError: false,
            emailExistsError: false,
            password: '',
            rePassword: '',
            passwordError: false,
            matchError: false,
            hidePasswords: true,
            userName: '',
            fullName: '',
            surname: '',
            number: '',
            address: '',
            address1: '',
            address2: '',
            addressError: false,
            town: '',
            county: '',
            postcode: '',
            terms: false,
            signature: '',
            signUpType: 2,
            trades: [],
            signatureError:false
        };

        toastr.error('Please enter your full name and sign the agreement', 'Error');
    }

    componentDidMount() {
        //    $('#bg-signature').jSignature();
    }

    goToThanksPage() {
        window.location = '/signup/dynamic-thankyou';
    }

    onFormSubmit = (event) => {
        event.preventDefault();
        
        if (this.state.fullName === '' || this.state.signature === '') {
            this.setState({signatureError:true});
            return false;
        }
        else {
            this.setState({signatureError:false});
            document.querySelector('.countdown-overlay').style.display = "block";
            document.querySelector('.countdown-wrapper').style.display = "flex";
            this.signUp().then(objSignUp => {
                this.goToThanksPage();
            });
        }
    }

    joinBuyingGroup = (callback) => {
        if (this.state.fullName === '' || this.state.signature === '') {
            toastr.error('Please enter your full name and sign the agreement', 'Error');
        }
        else {
            $.ajax({
                url: '/BuyingGroup/JoinBuyingGroup',
                data: {
                    fullName: `${this.state.fullName} ${this.state.surname}`,
                    signatureImage: this.state.signature,
                    address3: this.state.address3,
                    address1: this.state.address1,
                    address2: this.state.address2,
                    town: this.state.town,
                    postcode: this.state.postcode,
                    signupFormTrigger: 'TradeHelp Form',
                    sendBuyingGroupEmail: this.props.signUpFormVals.sendBuyingGroupEmail
                },
                type: 'POST',
                error: function (e) {
                    console.log(e);
                },
                success: function (d) {
                    callback && callback();
                    return true;
                }
            });
            return true;
        }

    }

    signUp = async () => {
        
            const {
                email,
                campaignName,
                password,
                trades,
                postcode,
                address1,
                address2,
                town,
                county,
                number,
                signature,
                fullName: firstName,
                surname,
            } = this.state;

            let result = await fetch('/api/v1/Signup/process-dynamic', {
                method: 'POST',
                body: JSON.stringify({
                    email,
                    companyName: campaignName,
                    password,
                    firstName,
                    surname,
                    trades,
                    address1,
                    address2,
                    town,
                    county,
                    postcode,
                    number,
                    marketingSource: this.props.signUpFormVals.marketingSource,
                    role: this.props.signUpFormVals.role,
                    triggerKey: this.props.signUpFormVals.triggerKey,
                    welcomeSubject: this.props.signUpFormVals.welcomeSubject,
                    signUpType: this.props.signUpFormVals.signUpType,
                    signature: signature,
                    // referralKey: $('#ReferralKey').val(),
                    genericForm: true,
                    sendBuyingGroupEmail: false
                }),
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            if (!result.ok) {
                const message = `An error has occured: ${result.status}`;
                throw new Error(message);
            }
            result = await result.json();
            return result;
    };

    checkIsEmailExist = async () => {
        const { email } = this.state;

        let isEmailAlreadyExist = false;

        try {
            let result = await fetch('/api/v1/signup/user-exists', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ UserName: email }),
            });
            result = await result.json();
            isEmailAlreadyExist = result.isUserExist;

        } catch (e) {
            console.error(e);
        }

        this.setState({ emailExistsError: isEmailAlreadyExist });

    };

    changeTextField = (fieldName, validationRule) => ({ target: { value } }) => {
        this.setState({ [fieldName + 'Error']: validationRule && !validationRule.test(value) })
        this.setState({ [fieldName]: value });
    }

    checkPassword = ({ target: { value } }) => {
        if (value.length < 8 || value.length > 16) { this.setState({ passwordError: true }) }
    };

    checkPasswordMatch = ({ target: { value } }) => {
        this.setState({ rePassword: value });
        this.setState({ matchError: (value.length < 1 || (this.state.rePassword !== this.state.password)) ? true : false });
    };

    minLength = (fieldName, validationRule) => ({ target: { value } }) => {
        const isValid = validationRule && validationRule.test(value);
        this.setState({ [fieldName + 'Error']: !isValid });
        if (fieldName == "email" && isValid) {
            this.checkIsEmailExist();
        }
    };

    checkAddress = () => ({ target: { value } }) => {
        if (value.length < 1) { this.setState({ addressError: true }) }
    }

    onChangeAddress = (value) => {
        let address = {
            address1: value.address[0] || '',
            address2: value.address[1] || '',
            town: value.city || '',
            county: value.county || '',
            postcode: value.postcode,
        };
        this.setState(address);
        this.setState({
            addressError: false, address: `${value.address.join(', ')}, ${value.city}, ${value.county + ',' || ''}${value.postcode}`,
        });
    };

    onChangeTrades = (value) => () => {
        const { trades } = this.state;
        if (trades.indexOf(value) === -1) {
            this.setState({
                trades: [...trades, value],
            });
        } else {
            this.setState({
                trades: trades.filter((item) => item !== value),
            });
        }
    };

    onChangeTerms = () => {
        const { terms } = this.state;
        this.setState({
            terms: !terms,
        });
        const arrFields = ['fullName', 'surname', 'campaignName', 'number', 'email', 'password'];
        arrFields.forEach(el => this.setFalse(el));
    };

    setFalse = (el) => {
        if (!this.state[el]) {
            this.setState({ [`${el}Error`]: true })
        }
    };

    isFormValid = () => {
        return true;
    };

    canGoNext = () => {
        const {
            fullName,
            surname,
            campaignName,
            number,
            email,
            emailError,
            emailExistsError,
            password,
            passwordError,
            matchError,
            address,
            signature,
            trades,
            terms
        } = this.state;

        return (
            fullName &&
            surname &&
            campaignName &&
            number &&
            email &&
            !emailError &&
            !emailExistsError &&
            password &&
            !passwordError &&
            !matchError &&
            trades.length > 0 &&
            address &&
            terms
        );
    };


    setButtonState = () => {
        if (this.canGoNext()) {
            return "btn btn-primary w-100 mt-4 mb-5 pulse-grow-on-hover dynamic-bgColour";
        }
        else {
            return "btn w-100 mt-4 mb-5";
        }
    }

    setSigState = () => {
        if (this.canGoNext()) {
            return "signatureWrapper p-3 showSig";
        }
        else {
            return "signatureWrapper p-3";
        }
    }

    onSignatureEnd = () => {
        if (this.canGoNext()) {
            this.setState({
                signature: this.sigCanvas.toDataURL('image/jpeg', 100),
                signatureError:false
            });
        }
        else {
            this.sigCanvas.clear();
        }
    };

    onClear = (isMobile) => {
        if (isMobile) {
            this.sigCanvasMobile.clear();
        }
        else {
            this.sigCanvas.clear();
        }
        this.setState({ signature: '' });
    };


    handleSideBarType = () => {
        if (this.props.signUpFormVals.sideBarImage) {
            return this.props.signUpFormVals.sideBarImage;
        } else {
            return null;
        }
    };

    render() {
        const {
            campaignName,
            email,
            emailError,
            emailExistsError,
            passwordError,
            matchError,
            password,
            rePassword,
            terms,
            fullName,
            surname,
            number,
            address,
            addressError,
            trades
        } = this.state;

        let bullets = this.props.signUpFormVals.bullets != null ? this.props.signUpFormVals.bullets.map((bullet, index) =>
            <div className="row py-md-4" key={index}>
                <div className="col-2"> <i className="fa fa-check text-light" aria-hidden="true"> </i></div>
                <div className="col-10"> <p className="benefits-text">{bullet.bulletText}</p> </div>
            </div>
        ) : [];
        const logoSection =
            <div className="col-12 pb-5 d-flex justify-content-center">
                <img className="d-flex mx-auto mx-md-0 pb-3 img-fluid" src={this.props.signUpFormVals.logoImage} alt="Company Logo" />
            </div>

        return (
            <div className="signupFormStyles">
                <div className="container-fluid signup-checkatrade">
                    <div className="row">
                        <div className="col-12 col-md-5 px-0 sidebar-wrapper">
                            <div
                                className="dynamic-bg dynamic-sidebarBg dynamic-gradient benefits pt-5"
                                style={{
                                    background: this.props.signUpFormVals.sideBarBackground,
                                    backgroundImage: this.handleSideBarType(),
                                }}
                            >
                                <div className="container benefits-container">
                                    <div className="row pt-3">

                                        {logoSection}

                                    </div>

                                    {bullets}

                                    <div className="row pt-5 pb-5">
                                        <div className="col-12">
                                            <p className="terms-text">{this.props.signUpFormVals.asterix}</p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-7 pt-5 mx-auto form-section">
                            <h2 className="col-12 pt-3 pt-md-5 pl-0 dynamic-heading">Join the FREE TradeHelp Network</h2>

                            <p className="col-12 pt-2 pb-4 pl-0">{this.props.signUpFormVals.intro}</p>
                            


                            {console.log(this.state)}
                            {console.log(this.canGoNext())}
                            <form onSubmit={this.onFormSubmit}>
                                <div className="row">
                                    <div className="form-group col-md-6 py-2">

                                        <MinTextField
                                            value={fullName}
                                            fieldName="fullName"
                                            placeholder="First Name (*)"
                                            onChange={this.changeTextField}
                                            onBlur={this.minLength}
                                            validationRule={/[A-Z0-9._%+-].{2,30}$/i}
                                            isValid={!this.state.fullNameError}
                                        />
                                    </div>
                                    <div className="form-group col-md-6 py-2">
                                        <MinTextField
                                            value={surname}
                                            fieldName="surname"
                                            placeholder="Surname (*)"
                                            onChange={this.changeTextField}
                                            onBlur={this.minLength}
                                            validationRule={/[A-Z0-9._%+-].{2,60}$/i}
                                            isValid={!this.state.surnameError}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-md-6 py-2">
                                        <MinTextField
                                            value={campaignName}
                                            fieldName="campaignName"
                                            placeholder="Company Name (*)"
                                            onChange={this.changeTextField}
                                            onBlur={this.minLength}
                                            validationRule={/[A-Z0-9._%+-].{1,80}$/i}
                                            isValid={!this.state.campaignNameError}
                                        />
                                    </div>
                                    <div className="form-group col-md-6 py-2">
                                        <MinTextField
                                            value={number}
                                            type="tel"
                                            fieldName="number"
                                            placeholder="Contact Number (*)"
                                            onChange={this.changeTextField}
                                            onBlur={this.minLength}
                                            validationRule={/[A-Z0-9._%+-].{2,20}$/i}
                                            isValid={!this.state.numberError}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-md-12 py-2">
                                        <MinTextField
                                            value={email}
                                            fieldName="email"
                                            placeholder="Email Address (*)"
                                            validationRule={/[A-Z0-9._%+-].{2,150}$/i}
                                            isValid={!this.state.emailError}
                                            onChange={this.changeTextField}
                                            onBlur={this.minLength}
                                        />
                                        {this.state.emailExistsError && 
                                        <span className="signup-form__error-message">This email is already in use</span>}
                                    </div>
                                    <div className="form-group col-md-6 py-2">
                                        <PasswordTextField
                                            value={password}
                                            fieldName="password"
                                            placeholder="Password"
                                            onChange={this.changeTextField}
                                            onBlur={this.checkPassword}
                                            autoComplete="new-password"
                                            validationRule={/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,16}$/g}
                                            isValid={!passwordError}
                                            errorText={
                                                'Password must be at least 8 characters, no more than 16 characters, and must include at least one upper case letter, one lower case letter, and one numeric digit.'
                                            }
                                        />
                                    </div>
                                    <div className="form-group col-md-6 py-2">
                                        <PasswordTextField
                                            value={rePassword}
                                            fieldName="rePassword"
                                            placeholder="Re-enter password"
                                            autoComplete='new-password'
                                            onChange={this.changeTextField}
                                            onBlur={this.checkPasswordMatch}
                                            isValid={!matchError}
                                            errorText={
                                                'Passwords must match'
                                            }
                                        />
                                    </div>
                                </div>

                                <div className="form-group mb-4 py-2">
                                    <AddressAutocomplete
                                        value={address}
                                        onChange={this.onChangeAddress}
                                        onBlur={this.checkAddress}
                                        isValid={!this.state.addressError}
                                        placeholder="Type in Postcode or Address and then select from list (*)"
                                    />
                                </div>
                                <div className="form-group mt-5 mb-4">
                                    <h3 className="dynamic-heading">Select Your Trades</h3><small>(*Please select at least one trade)</small>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-md-4">
                                        <div>
                                            <input className="form-check-input dynamic-border me-2" type="checkbox" value="3" onChange={this.onChangeTrades(3)} id="trade_plumbing" />
                                            <label className="form-check-label" htmlFor="trade_plumbing">Heating & Plumbing</label>
                                        </div>
                                        <div>
                                            <input className="form-check-input dynamic-border me-2" type="checkbox" value="7" onChange={this.onChangeTrades(7)} id="trade_home" />
                                            <label className="form-check-label" htmlFor="trade_home">Home Improvement</label>
                                        </div>
                                        <div>
                                            <input className="form-check-input dynamic-border me-2" type="checkbox" value="4" onChange={this.onChangeTrades(4)} id="trade_electrical" />
                                            <label className="form-check-label" htmlFor="trade_electrical">Electrical</label>
                                        </div>
                                        <div>
                                            <input className="form-check-input dynamic-border me-2" type="checkbox" value="9" onChange={this.onChangeTrades(9)} id="trade_joinery" />
                                            <label className="form-check-label" htmlFor="trade_joinery">Joinery</label>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <div>
                                            <input className="form-check-input dynamic-border me-2" type="checkbox" value="15" onChange={this.onChangeTrades(15)} id="trade_windows" />
                                            <label className="form-check-label" htmlFor="trade_windows">Windows & Doors</label>
                                        </div>
                                        <div>
                                            <input className="form-check-input dynamic-border me-2" type="checkbox" value="12" onChange={this.onChangeTrades(12)} id="trade_kitchen" />
                                            <label className="form-check-label" htmlFor="trade_kitchen">Kitchens</label>
                                        </div>
                                        <div>
                                            <input className="form-check-input dynamic-border me-2" type="checkbox" value="14" onChange={this.onChangeTrades(14)} id="trade_landscaping" />
                                            <label className="form-check-label" htmlFor="trade_landscaping">Landscaping</label>
                                        </div>
                                        <div>
                                            <input className="form-check-input dynamic-border me-2" type="checkbox" value="8" onChange={this.onChangeTrades(8)} id="trade_tiling" />
                                            <label className="form-check-label" htmlFor="trade_tiling">Tiling</label>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <div>
                                            <input className="form-check-input dynamic-border me-2" type="checkbox" value="11" onChange={this.onChangeTrades(11)} id="trade_decorating" />
                                            <label className="form-check-label" htmlFor="trade_decorating">Decorating</label>
                                        </div>
                                        <div>
                                            <input className="form-check-input dynamic-border me-2" type="checkbox" value="13" onChange={this.onChangeTrades(13)} id="trade_roofing" />
                                            <label className="form-check-label" htmlFor="trade_roofing">Roofing</label>
                                        </div>
                                        <div>
                                            <input className="form-check-input dynamic-border me-2" type="checkbox" value="10" onChange={this.onChangeTrades(10)} id="trade_plastering" />
                                            <label className="form-check-label" htmlFor="trade_plastering">Plastering</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group d-flex justify-content-between">

                                    <div className="form-check">
                                        <div className="pb-2 mt-4 d-flex">
                                            <input className="form-check-input dynamic-border checkbox-square flex-shrink-0" type="checkbox" value="" onChange={this.onChangeTerms} id="termsCheck" />
                                            <label className="form-check-label terms-label" htmlFor="termsCheck">
                                                I have read and agree to the <a href={this.props.signUpFormVals.termsConditions} target="_blank">Terms and Conditions</a> and
                                                wish to register for free benefits of the TradeHelp Network and its Buying Group.</label>
                                        </div>
                                    </div>

                                </div>

                                <div className="col-12 col-md-6">
                                    <div className={this.setSigState()}>
                                        <h3 className="dynamic-heading">Signature</h3>

                                        <SignatureCanvas
                                            ref={(ref) => {
                                                this.sigCanvas = ref;
                                            }}
                                            canvasProps={{ width: 350, height: 130, className: 'sigCanvas' }}
                                            onEnd={this.onSignatureEnd.bind(this)}
                                            backgroundColor={'#ffffff'}
                                        />
                                        <button
                                            type="button"
                                            onClick={() => { this.onClear(false); }}
                                            className="btn-grey"
                                        >
                                            Clear Signature
                                        </button>
                                
                                    </div>
                                    {this.state.signatureError === true && <span style={{ color: "#ED1F45", fontSize: "12px" }}>Please provide a signature</span>}
                                </div>

                                <button
                                    type="submit"
                                    className={this.setButtonState()}
                                    disabled={!this.canGoNext()}
                                >
                                    Join for FREE
                                </button>
                            </form>
                        </div>
                    </div>

                </div>
                <div className="countdown-overlay"></div>
                <div className="countdown-wrapper justify-content-center align-items-center">
                    <span className="countdown-loader"><img className="img-fluid" src="/img/tradehelp-icon.png" alt="Tradehelp Icon" /></span>

                </div>

            </div>
        );
    }
}

export default SignUpPageGeneric;