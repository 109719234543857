import React from 'react';
import ReactDOM from 'react-dom/client';
import FormWrapper from "./SignupForms/FormWrapper";
import "./Styles/normalize.css";
import "./Styles/main.scss";
import CreateUserService from "./Services/CreateUserService";
import Hotjar from '@hotjar/browser';
import Smartlook from 'smartlook-client'

(async () => {
    const userRecordingConfig = await CreateUserService.getUserRecordSettings();
    
    if (userRecordingConfig.data.useHotJar==true){
        const siteId = userRecordingConfig.data.siteId;
        const hotjarVersion = 6;
        
        Hotjar.init(siteId, hotjarVersion);    
    }
    else if (userRecordingConfig.data.useSmartLook==true) {
        Smartlook.init(userRecordingConfig.data.smartLookKey);
    }
})()

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
        <FormWrapper />
    </React.StrictMode>
);

