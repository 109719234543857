import React from "react";

const InputBlock = ({children}) => {
    return (
        <div className="col-12 col-lg-6 my-2 pe-lg-2">
            {children}
        </div>
        )
}

export default InputBlock;