import axios from "axios";
const createUser = (object)=>{
    return axios.post("/api/v1/signup", object);
}

const getMarketingSources = () => {
    return axios.get('/api/v1/Signup/marketing-sources');
}

const getTrades = () => {
    return axios.get('/api/v1/Signup/list-trades');
}

const getSignupClientConfig = (clientId) => {
    return axios.get(`/api/v1/Signup/${clientId}/client-config`);
}

const sendEmailLink=()=>{
    return axios.post('/api/v1/Signup/send-app-link', {})
}

const getOfficeLink=()=>{
    return axios.get('/api/v1/Signup/get-office-link');
}

const getUserRecordSettings = async () => {
    return await axios.get('/api/v1/Signup/user-record');
}

export default {
    createUser,
    getMarketingSources,
    getTrades,
    getSignupClientConfig,
    sendEmailLink,
    getOfficeLink,
    getUserRecordSettings
}