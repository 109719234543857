import React from "react";
import {
    Route,Routes, BrowserRouter as Router
} from "react-router-dom";
import TradeHelpDefault from "./TradeHelpDefault/TradeHelpDefault";
import FormLayout from "./Layout/FormLayout";
import Dynamic from "./Dynamic/Dynamic";
import FormProvider from "./TradeHelpDefault/Context/FormProvider";
import DynamicThankyou from "./DynamicThankyou/DynamicThankyou";

const FormWrapper = () => {
    return (
            <Router basename={"signup"}>
                <Routes>
                    <Route exact path={"/dynamic-thankyou"} element={<DynamicThankyou />}></Route>
                    <Route exact path={`/buytrade/:clientId`} element={
                        <FormProvider>
                            <FormLayout>
                                <TradeHelpDefault />
                            </FormLayout>
                        </FormProvider>
                            } />
                        
                    <Route exact path={`:clientId`} element={
                        <FormProvider>
                            <FormLayout>
                                <TradeHelpDefault />
                            </FormLayout> 
                        </FormProvider>} />
                    
                    <Route exact path={'/dynamic/:formName'} element={<Dynamic />} />
                </Routes>
            </Router>
        )
}

export default FormWrapper;