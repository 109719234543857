import React from "react";

export const TermsModal = ({ handleClose, termsLink }) => {

    
    return (
        <div className='modal'>
            <section className="modal-main">
                <iframe
                    src={termsLink}
                    width="100%"
                ></iframe>
                <button className="closeButton" type="button" onClick={handleClose}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" width="16" fill="#99a7ad">
                        <path d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z" />
                    </svg>
                </button>
            </section>
        </div>
    );
};

export default TermsModal;
