import React from "react";
import { useNavigate } from 'react-router-dom';

export const BackButton = () => {

    const navigate = useNavigate();

    const handleBack = () => {
        navigate(-1);
    };
    
    return (
        <div
            className="back-button-container"
            onClick={handleBack}
        >
            <div className="back-icon">
                <svg
                    viewBox="-6 -5 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    preserveAspectRatio="xMidYMin"
                >
                    <g clip-path="url(#clip0_410_2)">
                        <path d="M11.67 1.8701L9.9 0.100098L0 10.0001L9.9 19.9001L11.67 18.1301L3.54 10.0001L11.67 1.8701Z" fill="#009FDF" />
                    </g>
                    <defs>
                        <clipPath id="clip0_410_2">
                            <rect width="12" height="20" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            </div>


            <span className="back-text">Back</span>
        </div>
    );
};

export default BackButton;
