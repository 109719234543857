import React, {useEffect, useMemo, useState} from 'react';
import FormContext from "./FormContext";
import CreateUserService from "../../../Services/CreateUserService";
import TagManager from "react-gtm-module";

export default function FormProvider({children})
{
    const [state, setState] = useState({
        termsText:'',
        termsLink:'',
        privacyPolicyLink:'',
        pageTitle:'',
        pageDescription:'',
        showGasSafeEntry:false,
        showMarketingSelector:false,
        showFirstNameSurname: false,
        showLogoImage:false,
        backgroundImage:'',
        logoImage:'',
        documentTitle:'',
        trades:[],
        marketingSources:[],
        overridenHeaderImage:'',
        titleText:'',
        titleIcon:'',
        gTagManagerId:'',
        signupFormLink:''
    });

    const getClientConfig = (clientId) => {
        CreateUserService.getSignupClientConfig(clientId).then((response) => {
            updateState({
                titleText:response.data.titleText,
                titleIcon:response.data.titleIcon,
                termsText:response.data.termsAndConditions,
                termsLink:response.data.termsAndConditionsLink,
                privacyPolicyLink: response.data.privacyPolicyLink,
                pageTitle:response.data.createAccountPageTitle,
                pageDescription:response.data.createAccountPageDescription,
                showGasSafeEntry:response.data.showGasSafeEntry,
                showMarketingSelector:response.data.showMarketingSelector,
                showTradeSelector:response.data.showTradeSelector,
                showFirstNameSurname: response.data.showFirstNameSurname,
                logoImage:response.data.logoImage,
                backgroundImage:response.data.backgroundImage,
                documentTitle:response.data.titleText,
                overridenHeaderImage:response.data.overridenHeaderImage,
                gTagManagerId:response.data.gTagManagerId, 
                signupFormLink:response.data.signupFormLink
            });
        })
    }
    
    useEffect(()=> {
        setUpGTagManager();
    }, [state.gTagManagerId])

    const getMarketingSources = () => {
        CreateUserService.getMarketingSources().then((response) => {
            updateState({
                marketingSources:response.data.collection
            });
        });
    }

    const getTrades = () => {
        CreateUserService.getTrades().then((response) => {
            updateState({
                trades:response.data.collection
            });
        });
    }

    const updateState = (newState = {}) => {
        setState((_state) => ({
            ..._state,
            ...newState,
        }));
    };
    
    const setUpGTagManager = () => {
        if (state.gTagManagerId!=='') {
            const tagManagerArgs = {
                gtmId: state.gTagManagerId
            }

            TagManager.initialize(tagManagerArgs);
        }
    }
    
    const context = useMemo(() => ({
        globalState: state,
        actions: {
            updateState: updateState,
            getClientConfig: getClientConfig,
            getMarketingSources:getMarketingSources,
            getTrades: getTrades,
            setUpGTagManager: setUpGTagManager
        }
    }));

    
    return <FormContext.Provider value={context}>{children}</FormContext.Provider>
}