import React from 'react';

export default class ModalWindow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isFetching: false,
            peeps: ""
        };
    }
    
    componentDidMount() {
        this.setState({ ...this.state, isFetching: true });
        fetch(this.props.src)
            .then(response => response.text())
            .then(result => {
                this.setState({ peeps: result, isFetching: false });
            })
            .catch(e => {
                console.log(e);
                this.setState({ ...this.state, isFetching: false });
            });
        this.setState({ ...this.state, isFetching: false });
    }

    createMarkup() {
        return {__html: this.state.peeps}
    }
    
    render() {
        const { id, className = '' } = this.props;

        return (
            
            <div id={id} className={`modal ${className}`}>
                <h1>Terms and Conditions</h1>
                <p>{this.state.isFetching ? 'Fetching terms and conditions...' : ''}</p>
                <div dangerouslySetInnerHTML={this.createMarkup()}></div>
            </div>
        );
    }
}
