import React, { useContext } from "react";
import FormContext from "../TradeHelpDefault/Context/FormContext";
import { Helmet } from "react-helmet";

const FormLayout = ({ children }) => {
    const formContext = useContext(FormContext);
    return (<>
        <Helmet>
            <meta charSet="utf-8" />
            <title>{formContext.globalState.titleText}</title>
            <link rel="icon" href={formContext.globalState.titleIcon} />
            <link rel="canonical" href="http://mysite.com/example" />
            <script src="https://kit.fontawesome.com/3f892d1656.js" crossorigin="anonymous"></script>
        </Helmet>
        <div class="top-bar-img">
            <img src={formContext.globalState.overridenHeaderImage} />
        </div>

        {children}
    </>);
}

export default FormLayout;