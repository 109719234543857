import React, { memo } from 'react';
import clsx from 'clsx';

const MinTextField = memo(
    ({ value, validationRule, fieldName, errorText, onChange, onBlur = () => { }, isValid = true, autoComplete, ...rest }) => {
        
        return (
            <div className="signup-form__input-password">
                <input
                    onBlur={onBlur(fieldName,validationRule)}
                    type="text"
                    value={value}
                    className={clsx('signup-form__input', {
                        ['signup-form__input-error']: !isValid,
                    })}
                    onChange={onChange(fieldName, validationRule)}
                    {...(autoComplete && { autoComplete })}
                    {...rest}
                />
                {!isValid && <span className="signup-form__error-message">{errorText ? errorText : 'Required field'}</span>}
            </div>
        );
    }
);

export default MinTextField;
