import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import TradeHelpLogo from "../../img/tradehelp-logo.png";
import ThankYouIcon from "../../img/thankyou-icon.png";
import OfficeIcon from "../../img/office-icon.png";
import AppStoreButton from "../../img/app-store.png";
import GooglePlayButton from "../../img/google-play.png";
import CreateUserService from "../../Services/CreateUserService";
import toastr from "toastr";

const DynamicThankyou = () => {
    const [linkEmailed, setLinkEmailed] = useState(false);
    const [officeLink, setOfficeLink]=useState('https://office.tradehelp.co.uk/Account/Login');
    const emailMeLink = () => {
        CreateUserService.sendEmailLink().then((response) => {
            setLinkEmailed(response.data);
        })
    }
    
    useEffect(()=>{
        CreateUserService.getOfficeLink().then((response)=>{
            setOfficeLink(response.data.officeLink);
        })
    }, []);
    

    return (
        <div className="signupFormStyles">
            <div className="dynamicThankyouPage">

                <div className="container-fluid logoHeader">
                    <div className="container text-center text-md-start">
                        <img src={TradeHelpLogo} />
                    </div>
                </div>


                {/* DESKTOP VERSION */}

                <div className="contentContainerDesktop">

                    <div className="contentBoxDesktop">

                        <img src={ThankYouIcon} className="thankYouIcon" />

                        <h3 className="dynamic-heading mb-4">Congratulations! You’re all set...</h3>

                        <p>A member of the team will be in touch shortly to discuss the benefits available and get you up and running!</p>

                        <p>In the meantime log into your account below and take a look around.</p>

                        <p>If you have any questions you can call us on <a type="tel" href="01978 666 887">01978 666 887</a> or email <a type="mail" href="info@tradehelp.co.uk">info@tradehelp.co.uk</a>.</p>

                        <a href={officeLink} target="_blank">
                            <button className="btn btn-primary w-100 mt-4 pulse-grow-on-hover dynamic-bgColour">Go to TradeHelp Office</button>
                        </a>


                        <hr className="my-5" />

                        <h3 className="dynamic-heading mb-4">Download the TradeHelp app</h3>

                        <p>Download the TradeHelp app from the App Store or Google Play to access the unlimited, free tools on your mobile device.</p>

                        <p>Once downloaded, select ‘Sign In’ and use the email address and password you have just provided to access your account.</p>

                        <div className="d-flex mt-4">

                            <a href="https://play.google.com/store/apps/details?id=com.TradeHelp.Mobile&hl=en_GB" target="_blank">
                                <img className="appButton me-4" src={GooglePlayButton} />
                            </a>

                            <a href="https://apps.apple.com/gb/app/tradehelp-app/id1266213426" target="_blank">
                                <img className="appButton" src={AppStoreButton} />
                            </a>

                        </div>

                        {linkEmailed === true ?

                            <div className="d-flex align-items-center justify-content-center mt-5 emailSentAlert">

                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle-fill me-2" viewBox="0 0 16 16">
                                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                    </svg>
                                </div>

                                <p>Email sent</p>

                            </div>
                            :
                            <button onClick={emailMeLink} className="btn btn-primary w-100 mt-5 pulse-grow-on-hover dynamic-bgColour">Email me the link</button>}

                    </div>

                </div>



                {/* MOBILE VERSION */}

                <div className="contentContainerMobile">

                    <div className="contentBoxMobile text-center">

                        <h3 className="dynamic-heading mb-4">Congratulations! You’re all set...</h3>

                        <p>A member of the team will be in touch shortly to discuss the benefits available and get you up and running!</p>

                        <p>In the meantime download the TradeHelp app from the App Store or Google Play to access the unlimited free tools.</p>

                        <p>Once downloaded, select ‘Sign In’ and use the password and email address you have just provided to access your account.</p>

                        <p className="pb-2">If you have any questions you can contact us on <a type="tel" href="01978 666 887">01978 666 887</a> or email <a type="mail" href="info@tradehelp.co.uk">info@tradehelp.co.uk</a>.</p>

                        <div className="d-flex flex-column align-items-center mt-4">

                            <a href="https://play.google.com/store/apps/details?id=com.TradeHelp.Mobile&hl=en_GB" target="_blank">
                                <img className="appButton mb-3" src={GooglePlayButton} />
                            </a>

                            <a href="https://apps.apple.com/gb/app/tradehelp-app/id1266213426" target="_blank">
                                <img className="appButton mb-3" src={AppStoreButton} />
                            </a>

                        </div>

                    </div>


                    <div className="bottomOfficeSection text-center">

                        <img src={OfficeIcon} className="officeIcon" />

                        <h3 className="dynamic-heading mb-4">Log into TradeHelp Office on Desktop</h3>

                        <p>You can also access your account via your laptop or PC.</p>

                        <p>Log in via the TradeHelp website or go to <a href="https://office.tradehelp.co.uk" target="_blank">office.tradehelp.co.uk</a> using the same password and email address to access your account.</p>

                        {linkEmailed === true ?
                            <div className="d-flex align-items-center justify-content-center mt-4 emailSentAlert">

                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle-fill me-2" viewBox="0 0 16 16">
                                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                    </svg>
                                </div>

                                <p>Email sent</p>

                            </div>
                            :
                            <button onClick={emailMeLink} className="btn btn-primary w-100 mt-4 pulse-grow-on-hover dynamic-bgColour">Email me the link</button>}

                    </div>

                </div>



            </div>
        </div>
    );
}

export default DynamicThankyou;