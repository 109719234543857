import * as yup from 'yup';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { React, useContext, useEffect, useState } from 'react';
import CreateUserService from '../../Services/CreateUserService';
import InputBlock from '../FormElements/InputBlock/InputBlock';
import BackButton from '../TradeHelpDefault/BackButton/BackButton';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import FormContext from './Context/FormContext';
import TermsModal from './TermsModal/TermsModal';

const TradeHelpDefault = () => {
	const formContext = useContext(FormContext);
	const { clientId } = useParams();

	const [passwordShown, setPasswordShown] = useState(false);

	const togglePassword = (e) => {
		e.preventDefault();
		setPasswordShown(!passwordShown);
	};

	const [showTerms, setShowTerms] = useState(false);
	const [termsModalLink, setTermsModalLink] = useState('');

	const toggleTerms = (e, termsLink) => {
		e.preventDefault();
		setTermsModalLink(termsLink);
		setShowTerms(!showTerms);
	};

	useEffect(() => {
		formContext.actions.getMarketingSources();
		formContext.actions.getTrades();
		formContext.actions.getClientConfig(clientId);
	}, []);

	return (
		<div className='tradehelpDefaultStyles'>
			<div className='container-fluid'>
				{showTerms ? <TermsModal termsLink={termsModalLink} handleClose={toggleTerms} /> : null}

				<div className='row align-items-center'>
					<div className='col-12 col-lg-7 p-0'>
						<div className='input-container-lg mx-auto'>
							<BackButton />
							<h1 className='page-heading'>{formContext.globalState.pageTitle}</h1>

							<p className='subheading'>
								{formContext.globalState.pageDescription}{' '}
								{formContext.globalState.signupFormLink !== '' && (
									<>
										(Existing TradeHelp Network members do not need to register. Simply{' '}
										<span
											className='termsLink'
											onClick={() => {
												window.location = formContext.globalState.signupFormLink;
											}}>
											log in
										</span>{' '}
										using your existing TradeHelp credentials.)
									</>
								)}
							</p>

							{/* <div className="block-heading">Enter your details</div> */}

							<Formik
								initialValues={{
									emailAddress: '',
									companyName: '',
									password: '',
									firstName: '',
									lastName: '',
									telephone: '',
									gasSafeNumber: '',
									selectedMarketingSource: -1,
									clientId: clientId,
									agreedByTerms: true,
									confirmPassword: ''
								}}
								validationSchema={yup.object().shape({
									email: yup
										.string()
										.typeError('Email is required')
										.required('Email is required')
										.email('Email is invalid')
										.test('Unique Email', 'Email already in use', function (value) {
											return new Promise((resolve, reject) => {
												axios
													.post('/api/v1/Signup/user-exists', { userName: value })
													.then((res) => resolve(!res.data.isUserExist));
											});
										}),
									companyName: yup.string().required('Company name is required'),
									password: yup
										.string()
										.required('Password is required')
										.matches(
											/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,16})/,
											'Password must be at least 8 characters, no more than 16 characters, and must include at least one upper case letter, one lower case letter, and one numeric digit.'
										),
										firstName: formContext.globalState.showFirstNameSurname ? yup.string().required('First name is required') : yup.string(),
										lastName: formContext.globalState.showFirstNameSurname ? yup.string().required('Last name is required') : yup.string(),
									telephone: yup.string(),
									agreedByTerms: yup.bool().oneOf([true], 'The terms and conditions must be accepted'),
									gasSafeNumber: yup
										.string()
										.test('Gas Safe Required', 'Gas Safe Number is required', function (value) {
											if (formContext.globalState.showGasSafeEntry === true) {
												return value != null;
											} else {
												return true;
											}
										}),
								})}
								onSubmit={(values, { setSubmitting }) => {
									CreateUserService.createUser(values).then((response) => {
										window.location = response.data;
										setSubmitting(false);
									});
								}}>
								{({ isSubmitting, errors, touched }) => (
									<Form>
										<div className='row'>
											<InputBlock>
												<label htmlFor='email'>Email address</label>
												<Field type='email' name='email' placeholder='Please enter email' />
												<ErrorMessage name='email' component='span' className='field-error' />
											</InputBlock>
											<InputBlock>
												<label htmlFor='password'>Password</label>
												<div className='d-flex-row'>
													<Field
														type={passwordShown ? 'text' : 'password'}
														name='password'
														placeholder='Please enter password'
														className='password-input'
													/>
													<button className='password-button' onClick={togglePassword}>
														<i className={passwordShown ? 'bi bi-eye-fill' : 'bi-eye-slash-fill'}></i>
													</button>
												</div>
												<ErrorMessage name='password' component='span' className='field-error' />
											</InputBlock>

											{formContext.globalState.showFirstNameSurname && (
												<>
													<div className='pe-lg-2'>
														<hr className='hr-divider' />
													</div>
													<InputBlock>
														<label htmlFor='first-name'>First name</label>
														<Field type='text' name='firstName' placeholder='Please enter first name' />
														<ErrorMessage name='firstName' component='span' className='field-error' />
													</InputBlock>
													<InputBlock>
														<label htmlFor='last-name'>Last name</label>
														<Field type='text' name='lastName' placeholder='Please enter last name' />
														<ErrorMessage name='lastName' component='span' className='field-error' />
													</InputBlock>
												</>
											)}
											<InputBlock>
												<label htmlFor='company-name'>Company name</label>
												<Field type='text' name='companyName' placeholder='Please enter company name' />
												<ErrorMessage name='companyName' component='span' className='field-error' />
											</InputBlock>
											<InputBlock>
												<label htmlFor='telephone'>Telephone number</label>
												<Field type='tel' name='telephone' placeholder='Please enter telephone number' />
												<ErrorMessage name='telephone' component='span' className='field-error' />
											</InputBlock>
											<div className='pe-lg-2'>
												<hr className='hr-divider' />
											</div>

											{formContext.globalState.showGasSafeEntry && (
												<InputBlock>
													<label htmlFor='gas-safe'>Gas safe</label>
													<Field type='text' name='gasSafeNumber' placeholder='Please enter gas safe number' />
													<ErrorMessage name='gasSafeNumber' component='span' className='field-error' />
												</InputBlock>
											)}
											{formContext.globalState.showMarketingSelector && (
												<InputBlock>
													<label htmlFor='selectedMarketingSource'>How did you hear about TradeHelp?</label>
													<Field as='select' name='selectedMarketingSource' className='select-dropdown'>
														<option value='-1' disabled selected>
															Please select
														</option>
														{formContext.globalState.marketingSources.map((source) => {
															return <option value={`${source.id}`}>{source.name}</option>;
														})}
													</Field>
													<ErrorMessage name='selectedMarketingSource' component='span' className='field-error' />
												</InputBlock>
											)}
											{formContext.globalState.showTradeSelector && (
												<div className='container'>
													<label htmlFor='tradelist' className='mb-3 mt-4'>
														Select your trade(s)
													</label>
													<div role='group' aria-labelledby='checkbox-group' className='row ps-3'>
														{formContext.globalState.trades.map((trade, index) => {
															return (
																<div className='round col-6 col-md-4 my-1'>
																	<Field
																		type='checkbox'
																		name='tradelist'
																		id={`${trade.id}`}
																		value={`${trade.id}`}
																		className='checkbox'
																	/>
																	{trade.name}
																	<label htmlFor={`${trade.id}`}></label>
																</div>
															);
														})}
													</div>
												</div>
											)}
										</div>

										{formContext.globalState.showTradeSelector && (
											<div className='pe-lg-2'>
												<hr className='hr-divider' />
											</div>
										)}

										<div className='row my-4 pe-4 pe-md-2'>
											<div className='terms col-12'>
												<span>
													By continuing to Confirm you accept the
													<span
														className='termsLink'
														onClick={(e) => toggleTerms(e, formContext.globalState.termsLink)}>
														{' '}
														terms and conditions of use
													</span>
													{console.log(formContext)}
													{formContext.globalState.privacyPolicyLink ? (
														<>
															{' '}
															and
															<span
																className='termsLink'
																onClick={(e) => toggleTerms(e, formContext.globalState.privacyPolicyLink)}>
																{' '}
																privacy & cookies{' '}
															</span>
															policy.
														</>
													) : (
														'. '
													)}
													{' ' + formContext.globalState.termsText}
												</span>
												<br />
												<ErrorMessage name='agreedByTerms' component='span' className='field-error' />
											</div>
										</div>

										<div className='row justify-content-between align-items-center mb-5'>
											<div className='col-12 col-md-8 order-2 order-md-1'>
												<div>
													<img className='logo' src={formContext.globalState.logoImage} alt='TradeHelp Logo' />
												</div>
											</div>
											<div className='col-12 col-md-4 order-1 order-md-2 text-end mb-5 mb-md-0'>
												<button className='btn-cyan' type='submit' disabled={isSubmitting}>
													Confirm
												</button>
											</div>
										</div>
									</Form>
								)}
							</Formik>
						</div>
					</div>

					<div className='col-12 col-lg-5 p-0 image-container d-none d-lg-block'>
						<img src={formContext.globalState.backgroundImage} alt='TradeHelp Logo' />
					</div>
				</div>
			</div>
		</div>
	);
};

export default TradeHelpDefault;
